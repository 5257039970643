<template>
  <div class="container__img__aboutUs__containers__box4">
    <div class="container-fluid size d-flex mobile-aboutus">
      <div
        class="col-md-12 col-lg-12 col-xl-12 container__img__aboutUs__containers__box2 pl-5 pb-0 pt-5"
      >
        <h1 class="styles__title text-start m-auto text-center pt-5">
          POLÍTICA DE PRIVACIDAD DE MAQUÍLLATE.COM
        </h1>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          INFORMACIÓN QUE RECOPILAMOS
        </h2>

        <p class="styles__p m-auto text-center">
          Recopilamos información personal que nos proporcionas voluntariamente
          cuando te registras en nuestra plataforma, realizas una compra, creas
          una cuenta de usuario, publicas contenido, te comunicas con nosotros a
          través de correo electrónico u otros canales de comunicación, o de
          cualquier otra forma interaccionas con nuestra plataforma. La
          información que recopilamos puede incluir tu nombre, dirección de
          correo electrónico, dirección física, número de teléfono, información
          de pago, información de envío, información de registro, preferencias
          de maquillaje y cualquier otra información que nos proporciones.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          USO DE LA INFORMACIÓN
        </h2>
        <p class="styles__p m-auto text-center">
          Utilizamos la información que recopilamos para proporcionarte una
          experiencia de usuario personalizada, procesar tus pedidos, mejorar
          nuestra plataforma y servicios, comunicarnos contigo sobre nuestros
          productos y servicios, responder a tus preguntas y comentarios, y
          proporcionarte información relevante y ofertas especiales. También
          podemos utilizar la información para fines estadísticos y de
          investigación.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          PROTECCIÓN DE LA INFORMACIÓN
        </h2>

        <p class="styles__p m-auto text-center">
          Tomamos medidas técnicas y organizativas adecuadas para proteger la
          información que recopilamos y almacenamos. Utilizamos medidas de
          seguridad físicas, electrónicas y procedimentales para proteger tus
          datos personales contra el acceso no autorizado, la alteración, la
          divulgación o la destrucción.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          COMPARTIR INFORMACIÓN
        </h2>
        <p class="styles__p m-auto text-center">
          No compartimos información personal con terceros, excepto en los casos
          en que sea necesario para procesar tus pedidos, mejorar nuestra
          plataforma y servicios, o cumplir con las leyes y regulaciones
          aplicables. También podemos compartir información agregada y no
          identificable con terceros para fines estadísticos y de investigación.
        </p>
        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          COOKIES Y TECNOLOGÍAS SIMILARES
        </h2>
        <p class="styles__p m-auto text-center">
          Utilizamos cookies y otras tecnologías similares para recopilar
          información sobre tu actividad en nuestra plataforma. Esto nos ayuda a
          mejorar nuestra plataforma y servicios y a proporcionarte una
          experiencia de usuario personalizada. Puedes desactivar las cookies en
          tu navegador, pero esto puede afectar la funcionalidad de nuestra
          plataforma.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          CAMBIOS EN LA POLÍTICA DE PRIVACIDAD
        </h2>
        <p class="styles__p m-auto text-center">
          Nos reservamos el derecho de actualizar esta política de privacidad en
          cualquier momento y sin previo aviso. La versión más reciente de la
          política de privacidad se publicará en nuestra plataforma.
        </p>

        <h2 class="styles__title text-start m-auto text-center pt-4 pb-2">
          CONTÁCTANOS
        </h2>
        <p class="styles__p m-auto text-center">
          Si tienes alguna pregunta sobre nuestra política de privacidad o sobre
          cómo utilizamos tus datos personales, contáctanos en
          ayuda@maquillate.com
        </p>

        <p class="styles__p m-auto text-center">
          Gracias por utilizar nuestra plataforma de maquillaje y confiar en
          nosotros para proteger tu privacidad y datos personales.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FrequentQuestions',
};
</script>

<style>
.container__img__aboutUs__containers__box4 p {
  max-width: 900px;
  color: black;
}
</style>
