<template>
  <div class="row margin-top-section">
    <div class="col-sm-12">
      <h1 class="allClasses__subtitles mobile-space m-0">Todas las Clases</h1>
      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
        />
      </div>
      <div v-else class="row mt-4">
        <template
          v-if="getPublicClasses.items && getPublicClasses.items.length"
        >
          <card-class
            v-for="_class in getPublicClasses.items"
            :key="_class.id"
            :data="_class"
            class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 pb-2"
          />
        </template>
        <template v-else>
          <div class="text-center">
            <h3>No hay contenido disponible</h3>
          </div>
        </template>
      </div>
      <div v-if="getPublicClasses.items && getPublicClasses.items.length">
        <div
          v-if="totalPages > 1"
          class="pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FETCH_PUBLIC_CLASSES } from '../../../store/actions/public';
import { mapGetters } from 'vuex';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo.vue';
import BasePagination from '/src/components/BasePagination';
import CardClass from '../Courses/components/CardClass.vue';

export default {
  name: 'ClassFeaturedView',

  components: {
    SkeletonCardVideo,
    CardClass,
    BasePagination,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      perPage: 30,
    };
  },
  metaInfo () {
    const slug = this.$route.params.slug;
    const category = this.getPublicCategories.find((publicCategory) => publicCategory.slug === slug);
    console.log('category', category);
    return {
      title: category ? `${category.title}`: 'Explorar Clases - Maquillate.com',
    };
  },
  computed: {
    ...mapGetters(['getPublicClasses', 'getPublicCategories']),
    totalPages() {
      return Math.ceil(this.getPublicClasses.total / this.perPage);
    },
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.page = 1;
      this.isLoading = true;
      await this.fetchClassVideos({
        category_slug: slug,
        per_page: this.perPage,
        page: 1,
      });
    },
  },
  async mounted() {
    await this.fetchClassVideos();
  },
  methods: {
    async fetchClassVideos(
      filter = {
        per_page: 30,
        order_by: 'DESC',
        category_slug: this.$route.params.slug,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_CLASSES, filter)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.notify('danger', 'Tenemos problemas para cargar los clases');
        });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_PUBLIC_CLASSES, {
        page,
        per_page: this.perPage,
        category_slug: this.$route.params.slug,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.md-tabs-navigation .md-button-content {
  color: #fff;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background-color: #000;
}

.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:hover,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button:first-child:active,
.view-home-tabs
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active:first-child {
  background-color: #b7227e;
}

.card-all-classes {
  margin-bottom: 0.5rem !important;
}

.card-flex-basis {
  flex-basis: 100% !important;
}
</style>
